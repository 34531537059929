<template>
    <div class="header row-items">
        <div class="column-name">
            <div class="text" v-html="data.name"></div>
        </div>
        <div class="column-module" v-for="value in data.items">
            <div class="text">{{ value }}</div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        data: Object,
        typeArray: String
    }
}

</script>